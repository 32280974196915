information {
  margin-top: 1rem;
}

information aside {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 360px;
  align-items: center;
  /* margin: 1rem 15px 1rem 0; */
}

information aside>* {
  margin-bottom: 2rem;
}

information a {
  text-decoration: none;
  color: var(--greyMid);
}

information address {
  display: block;
  font-style: normal;
    margin-top:1rem;
}

information address, information phone, information ul {
  width: 300px;
}

information h3 {
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 3rem;
}

information phone h3 {
  background-image: var(--phoneRed);
}

information address h3 {
  background-image: var(--warehouse);
}

information hours h3 {
  background-image: var(--hours);
}

information ul {
  /* background: linear-gradient(180deg, transparent, #353535, transparent); */
  background-position: .5rem;
  background-repeat: repeat-y;
  background-size: 2px auto;
  display: block;
  font-size: 1rem;
  margin-bottom: 2rem;
}

information li {
  display: grid;
  grid-template-areas:
    "day day"
    "am state"
    "pm state";
  grid-row-gap: 1rem;
  /* margin-bottom: 1rem; */
}

information li.saturday {
  /* display: grid; */
  grid-template-areas: "day day" "am state" "pm state2";
}

information am, information pm {
  display: flex;
  align-items: center;
}

information am:before, information pm:before {
  content: "";
  display: flex;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--darkGreen);
  border-radius: 50%;
  margin-right: .5rem;
  background: var(--greenSoft);
}

information am {
  grid-area: am;
}

information pm {
  grid-area: pm;
}

information state:first-of-type {
  grid-area: state;
  place-self: center;
}

information state:nth-of-type(2) {
  grid-area: state2;
  place-self: center;
}

information h4 {
  grid-area: day;
  place-self: center;
  margin-top: 1rem;
  font-weight: normal;
  /* font-style: italic; */
}

information am.closed::before, information pm.closed::before {
  border-color: var(--darkRed);
  background-color: var(--darkRed);
}

information am.on-reservation::before, information pm.on-reservation::before {
  border-color: var(--darkYellow);
  background-color: var(--darkYellow);
}

information state.closed {
  background-color: var(--darkRed);
  color: white;
}

information state.on-reservation {
  background-color: var(--darkYellow);
}

information state {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 7rem;
  background-color: var(--greenSoft);
  border-radius: .5rem;
  margin-left: 1rem;
  padding: .2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: var(--darkBlue);
  font-size: .9rem;
}

information ul .on-reservation state, information ul .closed state {
  color: white;
}

information iframe {
  width: 100%;
  height: 360px;
  box-shadow: var(--shadow);
}

@media screen and (min-width: 768px) {
  information {
    align-items: stretch;
    display: flex;
    justify-content: center;
  }
  information iframe {
    height: 724px;
  }
}