header {
    width: 100vw;
    background-color: var(--darkBlue);
}

header img {
    /* logo */
    width: 40vw;
    max-width: 240px;
}

header>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - 1rem);
    padding: .5rem;
}

header a {
    display: block;
    text-align: center;
    color: white;
    text-decoration: none;
}

header a[href^="tel:"] {
    background-repeat: no-repeat;
    padding-left: 1.5rem;
    background-image: var(--phone);
}

div#adjustError {
    width: 268px;
    height: 200px;
    margin: 0 auto;
    position: relative;
}

div#adjustError p {
    position: absolute;
    bottom: 0;
    text-align: center;
    padding: 1rem 2rem;
    margin: 0 auto;
    width: 200px;
    background-color: var(--darkBlue);
    color: #fff;
    border: 2px solid var(--darkYellow);
}

@media screen and (min-width: 992px) {
    header>div {
        /* width: fit-content; */
        max-width: 1024px;
        margin: auto;
        /* border:1px solid white; */
    }
}