imgSlider img {
  display: none;
}

imgSlider {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  /*   background: no-repeat scroll center 0/cover; */
  display: block;
  transition-property: opacity, filter;
  transition-duration: 2s;
  opacity: 0;
  filter: blur(15px);
  -webkit-filter: blur(15px);
}

imgSlider.fadein {
  opacity: .5;
  filter: blur(0px);
  -webkit-filter: blur(0px);
}

imgSlider, .slideContent {
  width: 100%;
  height: 100%;
}

.slideContent {
  margin-top: -100vh;
  display: grid;
  justify-content: space-around;
  position: relative;
  grid-template-rows: auto 5rem;
}

.slideContent * {
  margin: auto;
  color: white;
}

.slideContent #slideText {
  height: 40vh;
  display: flex;
  flex-direction: column;
  transition-property: opacity;
  transition-duration: 2s;
  text-align: center;
  opacity: 0;
}

.slideContent #slideText.fadein {
  opacity: 1;
}

.slideContent #slideText a {
  display: block;
  background-color: var(--darkRed);
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: .5rem;
  text-decoration: none;
}

.slideContent #slideText h2 {
  font-size: 2.8rem;
  font-weight: 700;
}

@media screen and (min-width: 992px) {
  imgSlider, .slideContent {
    height: 80vh;
    max-height: 800px;
  }
  .slideContent {
    margin-top: -80vh;
  }
}