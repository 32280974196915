#contact form {
  width: 88%;
  background: var(--greySuperLight);
  border-radius: .5rem;
  box-shadow: var(--shadow);
  margin: 2rem auto;
  padding: 20px 30px;
  max-width: 340px;
  box-sizing: border-box;
  position: relative;
  color: var(--darkBlue);
  max-width: 640px;
  display: grid;
  grid-template-areas: "titre" "tel" "nom" "mail" "msg" "button ";
  grid-template-rows: auto 3rem repeat(4, 1fr);
}

#contact label {
  font-weight: bold;
}

#contact label:first-of-type {
  grid-area: nom;
}

#contact label:nth-of-type(2) {
  grid-area: mail;
}

#contact label:last-of-type {
  grid-area: msg;
}

#contact form h2 {
  grid-area: titre;
  margin: .5rem 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--darkBlue);
  border-bottom: 1px solid var(--darkRed);
}

#contact form input, #contact textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: none;
  outline: none;
  resize: none;
  border: 0;
  transition: all .3s;
  border-bottom: 2px solid var(--greyLight);
}

#contact form input:focus {
  border-bottom: 2px solid var(--darkYellow);
}

#contact form button {
  margin-top: 1rem;
  grid-area: button;
  width: 80%;
  margin: auto;
}

#contact a {
  display: flex;
  background: var(--darkBlue);
  border-radius: .5rem;
  padding: .5rem .5rem .5rem 3rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-image: var(--phoneRed);
  background-size: 1.5rem;
  background-repeat: no-repeat;
  background-position: .5rem center;
  color: white;
  text-decoration: none;
  grid-area: tel;
  height: 70%;
  justify-self: center;
  align-self: center;
}

@media screen and (min-width: 768px) {
  #contact form aside {
    top: -65px;
  }
  #contact form {
    grid-template-areas: "titre tel" "nom nom" "mail mail" "msg msg" "button button";
    grid-template-rows: auto repeat(3, 6rem) 4rem;
  }
  #contact a {
    justify-self: end;
  }
}