footer {
  background-color: var(--darkBlue);
  width: 100%;
  font-size: small;
  padding: .5rem 0;
  text-align: center;
}

footer * {
  color: #bebfc6;
  text-decoration: none;
}

footer h3 {
  color: white;
  padding: .5rem 0;
}

footer a:hover {
  color: var(--darkYellow);
}

footer a {
  display: block;
}

footer .facebook::before {
  background-image: var(--facebookF);
  content: " ";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
}

footer>aside {
  border-top: 1px solid var(--darkRed);
  margin-top: 1rem;
  padding-top: 1rem;
}
footer aside p:first-of-type{
  margin-bottom:1rem;
}

@media screen and (min-width: 992px) {
  footer {
    display: flex;
    flex-wrap: wrap;
  }
  footer section:first-of-type {
    max-width: 800px;
  }
  footer section:last-of-type {
    max-width: 400px;
  }
  footer section, footer>aside {
    width: 90vw;
    margin: auto;
  }
  footer a {
    display: inline-block;
  }
  footer>aside {
    margin-top: 2rem;
  }
  footer>aside a::after {
    content: "|";
    margin-left: .4rem;
    margin-right: .2rem;
  }
  footer>aside a:last-of-type::after {
    content: "";
  }
  footer ul {
    list-style-type: initial;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
  }
  footer li {
    text-align: initial;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}