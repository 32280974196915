.equipements ul {
  max-width: 700px !important;
}

.equipements li {
  text-align: justify;
}

.equipements li h3 {
  margin: 1rem 0;
  text-align: center;
}

.equipements blockquote {
  font-style: italic;
  margin: 1rem;
}

.equipements aside {
  max-width: 1000px !important;
}