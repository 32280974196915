section.cards {
  margin-bottom: 8rem;
}

@media (min-width: 600px) {
  section.cards {
    margin-bottom: 0;
  }
}

.page-content {
  display: grid;
  grid-gap: 6%;
  padding: 1rem;
  /* max-width: 1024px; */
  margin: 0 auto;
}

@media (min-width: 600px) {
  .page-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 800px) {
  .page-content {
    grid-template-columns: repeat(4, 1fr);
  }
}

.btn {
  cursor: pointer;
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 0.65rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: white;
  background-color: var(--darkYellow);
  border: none;
  width: 90%;
}

/* article {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  padding: 1rem;
  width: 100%;
  text-align: center;
  color: whitesmoke;
  background-color: whitesmoke;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
} */

article {
  display: grid;
  grid-template-areas: "content";
  height: 220px;
  overflow: hidden;
  text-align: center;
  width: 100%;
}

@media screen AND (min-width: 600px) {
  article {
    height: 350px;
  }
}

article main {
  align-items: center;
  background: linear-gradient(0deg, rgba(4, 9, 30, 0.75) 30%, rgba(195, 23, 0, 0.30) 100%);
  color: white;
  display: grid;
  grid-area: content;
  grid-template-rows: 20% 80%;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}

article.satisfaction main {
  grid-template-rows: 20% 25% 40%;
}

@media screen AND (min-width: 800px) {
  article main {
    background: linear-gradient(0deg, rgba(4, 9, 30, 0.75) 0%, rgba(195, 23, 0, 0.30) 50%, rgba(243, 195, 0, 0.05) 75%);
    height: 6rem;
    margin-top: 255px;
    grid-template-rows: initial;
  }
  article.satisfaction main {
    grid-template-rows: initial;
  }
  article:hover main {
    -webkit-animation: cardAnimate 1s forwards;
    animation: cardAnimate 1s forwards;
  }
  @-webkit-keyframes cardAnimate {
    from {
      height: 6rem;
      margin-top: 100%;
    }
    to {
      height: 100%;
      margin-top: 0;
    }
  }
  @keyframes cardAnimate {
    from {
      height: 6rem;
      margin-top: 100%;
    }
    to {
      height: 100%;
      margin-top: 0;
    }
  }
}

article iframe {
  margin-top: 1rem;
  justify-self: center;
}

article img {
  grid-area: content;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  transition: width 1s, height 1s;
}

article h2 {
  margin-top: 1rem;
  font-size: 1.3rem;
  line-height: 1.2;
  text-shadow: var(--darkBlue) 1px 1px, var(--darkBlue) -1px 1px, var(--darkBlue) -1px -1px, var(--darkBlue) 1px -1px;
}

@media screen AND (min-width: 800px) {
  article h2 {
    height: 5rem;
  }
}

article ul {
  margin: 0;
  padding: 0;
}

article li {
  list-style: none;
  margin-top: 1rem;
}

article li::before {
  content: '';
  display: block;
  height: 2rem;
  background-size: contain;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: center;
}

article.devSurBanc li:first-of-type::before {
  background-image: var(--powerBench);
}

article.devSurBanc li:nth-of-type(2)::before {
  background-image: var(--wind);
}

article.fiabilite li::before {
  background-image: var(--checkedYellow);
}

article.reprogrammation li::before {
  background-image: var(--courbes);
}