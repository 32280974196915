section#distributeurs {
  display: flex;
  flex-direction: column;
}
section#distributeurs ul{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin:auto;
}
section#distributeurs li {
  display: flex;
  margin: .2rem 0;
}
section#distributeurs li:last-of-type {
  margin-bottom:0;
}

section#distributeurs li a {
  text-decoration: none;
}

section#distributeurs li *{
  padding: 0.2rem;
  font-size: .8rem;    
}

section#distributeurs address {
  text-align:left;
  max-width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

section#distributeurs h6 {
  width: calc(60vw - 0.4rem);
  background-color:var(--darkYellow);
  color: white;
  max-width: 300px;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
}