main .logo {
  height                : 5rem;
  /* width            : 3rem; */
  background-size       : contain;
  background-repeat     : no-repeat;
  background-position   : center;
  margin                : 4rem auto 0 auto;
}
main {
  background-color      : var(--greySuperLight);
}

main > h1 {
  color                 : var(--darkBlue);
  font-size             : 1.5rem;
  margin                : auto;
  text-align            : center;
  width                 : -webkit-fit-content;
  width                 : -moz-fit-content;
  width                 : fit-content;
}

main > h1 b {
  display               : block;
  color                 : var(--darkRed);
  font-size             : 2rem;
}

main > h1 small {
  font-size             : 1rem;
  display               : block;
  font-style            : italic;
}

reprogrammation table {
  width                 : 90%;
  margin                : 1rem auto;
  font-size             : 1.15rem;
  max-width             : 500px;
  text-align            : center;
  padding-bottom        : 1rem;
}

reprogrammation table tr td {
  padding               : 0.5rem 0;
  border-bottom         : 2px dotted #cccccc;
  vertical-align        : top;
}

reprogrammation table tr td:first-of-type {
  width                 : 50%;
}

reprogrammation table tbody tr:last-of-type td {
  border                : none;
}

reprogrammation table tbody tr td:last-of-type {
  color                 : var(--darkRed);
  font-weight           : bold;
}

reprogrammation table tbody tr:last-of-type td:last-of-type {
  font-size             : 1.3rem;
  color                 : var(--darkBlue);
}

reprogrammation gain {
  display               : block;
  background-color      : var(--darkYellow);
  color                 : white;
  font-size             : 0.8rem;
  text-align            : center;
}

em {
  text-align: center;
  display: block;
}

@media screen and (min-width: 992px) {
  headband > h1 {
    padding-top         : 15vh;
  }

  headband button#searchModalOpener {
    margin-top          : 3rem;
  }
}
