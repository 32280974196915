#about {
  display: block;
  margin: 3rem auto 0 auto;
}

#about p  {
  text-align: justify;
  margin-top: 1rem;
  max-width: 290px;
  margin: auto;
  width: 90%;
  max-width: 609px;
  margin-top:1rem;
}
#about picture img{
  width: 90%;
  max-width: 1334px;
}
#about > img:last-of-type{
  width: 100%;
  max-width: 609px;
  margin: 1rem auto 0 auto;
}

@media screen AND (min-width:768px) {
  #about {
    margin: 1rem auto;
  }
}
