modal {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: background-color 0.5s ease-out;
  z-index: 2;
}

modal.visible {
  background-color: rgba(0, 0, 0, 0.75);
}

modal #searchModal {
  position: absolute;
  background-color: var(--darkBlue);
  color: white;
  border: 1px solid var(--darkYellow);
}

modal #searchModal.opened {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: searchModalOuverture;
  animation-name: searchModalOuverture;
}

modal #searchModal.end {
  height     : var(--yellowEndHeight);
  left       : var(--yellowEndLeft);
  top        : var(--yellowEndTop);
  width      : var(--yellowEndWidth);
  align-items: center;
  display    : flex;
}

modal ul#searchModalResume {
  width: 50%;
}

modal ul#searchModalResume button {
  background-color: white;
  width: 90%;
  margin: 1rem auto;
  padding: 1rem;
  border: 0;
}

modal ul#searchModalResume li {
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
}

modal #homeBrand {
  background-image: var(--allBrandButton);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 2rem;
  margin-bottom: 1rem;
  -webkit-animation: fadeIn .5s;
  animation: fadeIn .5s;
}

modal ul#searchModalResume li span {
  display: block;
  margin: 0.5rem auto;
  padding: 0.5rem 0;
  border: 1.75px solid var(--superDarkYellow);
  width: 90%;
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

modal div#searchModalList {
  width: 50%;
  border-left: 2px dotted var(--darkYellow);
  height: 90%;
  padding-left: 0.5rem;
  overflow-y: auto;
  display: flex;
  transition-property: width;
  transition-duration: 0.5s;
}
modal div#searchModalList li {
  margin: 0.3rem 0;
  cursor: pointer;
}

modal div#searchModalList li.separator,
#searchModalList li.title {
  cursor: default;
  color: var(--darkYellow);
}

modal div#searchModalList div.loading {
  background-image: var(--icone);
  width: 50%;
  height: 50%;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.5;
  animation: fade ease 1s infinite;
  -webkit-animation: fade ease 1s infinite;
  -moz-animation: fade ease 1s infinite;
  -o-animation: fade ease 1s infinite;
  -ms-animation: fade ease 1s infinite;
  overflow: hidden;
  background-position: center;
}

modal div#searchModal > div:first-child#searchModalList {
  text-align: center;
  width: 100%;
  border: none;
}
modal div#searchModal > div:first-child#searchModalList ul {
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 992px) {
  modal ul#searchModalResume button {
    width: 50%;
  }
  modal ul#searchModalResume li span {
    width: 30%;
    padding: 0.5rem;
  }
}

@-webkit-keyframes searchModalOuverture {
  from {
    background-color: var(--darkYellow);
    left: var(--yellowLeft);
    top: var(--yellowTop);
    height: var(--yellowHeight);
    width: var(--yellowWidth);
  }

  50% {
    left: var(--yellowLeft);
    top: var(--yellowEndTop);
    height: var(--yellowEndHeight);
    width: var(--yellowWidth);
  }

  to {
    background-color: var(--darkBlue);
    left: var(--yellowEndLeft);
    top: var(--yellowEndTop);
    height: var(--yellowEndHeight);
    width: var(--yellowEndWidth);
  }
}

@keyframes searchModalOuverture {
  from {
    background-color: var(--darkYellow);
    left: var(--yellowLeft);
    top: var(--yellowTop);
    height: var(--yellowHeight);
    width: var(--yellowWidth);
  }

  50% {
    left: var(--yellowLeft);
    top: var(--yellowEndTop);
    height: var(--yellowEndHeight);
    width: var(--yellowWidth);
  }

  to {
    background-color: var(--darkBlue);
    left: var(--yellowEndLeft);
    top: var(--yellowEndTop);
    height: var(--yellowEndHeight);
    width: var(--yellowEndWidth);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}