body > section {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

body > section:nth-of-type(2n) {
    background: var(--greySuperLight);
}
  
body > section:nth-of-type(2n+1) {
    background: #fff;
}

body > section>p {
    margin-bottom: 1rem;
}

/*
body > section .cards{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

body > section .cards card {
    width: 90vw;
    max-width: 280px;
    display: block;
}

body > section .cards card img {
    width: 100%;
    border-radius: .25rem;
    border: 1px solid white;
    box-shadow: var(--shadow);
}

body > section .cards card li {
    color: var(--darkBlue);
    margin: .5rem 0;
    font-size: .8rem;
    text-align: left;
}

body > section .cards card li::before{
    display: inline-block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: .5rem;
}
body > section .cards card.fiabilite li::before{
    background-image: url("../images/respect.png");
}
body > section .cards card.devSurBanc li::before{
    background-image: url("../images/soufflerie.png");
}
body > section .cards card.reprog li::before{
    background-image: url("../images/courbes.png");
}
body > section .cards card.satisfaction li::before{
    display: none;
}

body > section .cards card h4 {
    color: var(--blackLight);
    text-align: center;
    width: 110%;
    font-size: .8rem;
    margin-bottom: 1rem;
}
*/

body > section .blocs a.pqp_lien,
body > section .blocs a.pqp_lien:visited {
    text-decoration: none;
    color: var(--greyMid);
}

@media screen and (min-width: 768px) {
    body > section > * {
        max-width: 1366px;
        margin: 0 auto 1rem auto;
    }
    body > section .cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
    }
}

@media screen and (min-width: 992px) {
    body > section .cards card {
        margin: 0 2rem;
    }
}

@media screen and (min-width: 1025px) {
    body > section .cards card {
        margin: 0 1rem;
    }
}