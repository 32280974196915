header {
  background-color: transparent;
  display: grid;
  grid-template-areas: "logo burger" "phone phone" "nav nav";
  padding-bottom: 1rem;
  position: fixed;
  transition-duration: 0.5s;
  transition-property: height, background-color;
  width: 100%;
  z-index: 2;
}

header.showBackground {
  background-color: var(--darkBlue);
}

header>a {
  grid-area: logo;
}

header a {
  text-decoration: none;
  color: white;
}
header a:hover {
  color: var(--darkYellow);
  border-color: white;
}

header phone {
  grid-area: phone;
  justify-self: center;
  display: flex;
  align-items: center;
}

header phone::before {
  background: center no-repeat;
  background-size: contain;
  background-image: var(--phone);
  content: " ";
  display: flex;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

header hamburger {
  grid-area: burger;
  background: center no-repeat;
  background-size: contain;
  background-image: var(--hamburger);
}

header nav {
  display: flex;
  flex-wrap: wrap;
  grid-area: nav;
  height: 0;
  justify-self: center;
  overflow: hidden;
  transition-duration: 0.2s;
  transition-property: all;
}

header nav.shown {
  height: 14rem;
  margin-top: 1rem;
}

header nav a {
  width: 100%;
  display: flex;
  align-items: center;
}

header nav a::before {
  content: " ";
  display: flex;
  width: 35%;
  height: 1rem;
  background: no-repeat center;
  background-size: contain;
  background-image: var(--icone);
}

@media screen AND (min-width: 768px) {
  header {
    grid-template-areas: ". logo . phone ." ". nav nav nav .";
  }
  header hamburger {
    display: none;
  }
  header nav {
    height: 100%;
    width: 100%;
    margin-top: 1rem;
    flex-wrap:initial;
    max-width: 1366px;
  }

  header nav.shown{
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
  }
  header nav a {
    justify-content: center;
    width: 33%;
    border-right: 1px solid white;
  }
  header nav a::before {
    content: initial;
  }
  header nav a:last-of-type {
    border-right: none;
  }
}

@media screen AND (min-width: 768px) {
  header {
    background-color: rgb(4 9 30 / 33%);
  }
}